<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img
        class="w-full h-full object-cover"
        src="https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
        alt=""
      />
      <div
        class="absolute inset-0 bg-primary"
        style="mix-blend-mode: multiply"
        aria-hidden="true"
      />
    </div>
    <div class="relative mx-auto py-24 px-10 sm:py-32 sm:px-6 lg:px-8">
      <h2 class="text-3xl text-center font-extrabold text-white sm:text-4xl">
        <span class="block">Contact Us Today.</span>
      </h2>
      <div class="h-3"></div>
      <div class="max-w-md mx-auto">
        <router-link to="/contact" class="btw bh"> Contact Us </router-link>
      </div>
    </div>
  </div>
</template>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="py-16 bg-gray-50 overflow-hidden">
    <div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
      <div class="text-base max-w-prose mx-auto lg:max-w-none">
        <h2
          class="text-base text-secondary font-semibold tracking-wide uppercase"
        >
          Kirby Brothers
        </h2>
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Our Services
        </p>
      </div>

      <div
        class="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72"
      >
        <p class="text-lg text-gray-900">
Over the last 30+ years Kirby Brothers has been working at the highest level across many services, delivering high quality consistent work to many different client across multiple sectors.

        </p>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
        <div class="relative z-10">
          <div class="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
            <p class="mt-4 text-lg text-gray-900">
We Specialise in Painting & Decorating contracting but also cater to other specific services like special finishes & Blowerproof Solutions.

            </p>
              <p class="mt-4 text-lg text-gray-900">
                <span class="font-bold">List of Sectors:</span>
                <ul>
                  <li>Painting Contracting</li>
                  <li>Special Finishes</li>
                  <li>Decorating</li>
                  <li>Blowerproof</li>
                </ul>
              </p>
          </div>
        </div>
          <div class="space-y-4">
                <div class="aspect-w-3 aspect-h-2">
                  <img
                    class="object-cover shadow-lg rounded-lg"
                    data-aos="fade-up"
                    src="https://images.unsplash.com/photo-1601661862050-3186484ca3ad?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1875&q=80"
                    alt=""
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
  <!-- BREAK BREAK -->
  <div
    class="relative bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
  >
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3" />
    </div>
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <h2
          class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
        >
          List of Services.
        </h2>
      </div>
      <div
        class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:grid-rows-2 lg:max-w-none"
      >
        <div
          v-for="post in posts"
          :key="post.title"
          class="flex flex-col rounded-lg shadow-lg overflow-hidden"
          data-aos="fade-up"
        >
          <div class="flex-shrink-0">
            <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
              <p class="text-xl font-semibold text-gray-900">
                {{ post.title }}
              </p>
              <p class="mt-3 text-base text-gray-500">
                {{ post.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="h-11"></div>
              <div class="mt-5 max-w-md mx-auto">
                  <router-link
                    to="/portfolio"
                    class="btg bh"
                  >
                    View Portfolio
                  </router-link>
                </div>
  </div>
</template>

<script>
const posts = [
  {
    title: "Painting Contracting",
    href: "#",
    category: { name: "Article" },
    description:
      "We provide high quality internal and external painting solutions for all size projects across all sectors. With a company guarantee of delivering the highest quality work in the business!",
    imageUrl: require("@/assets/dulux.jpg"),
  },
    {
    title: "Decorating",
    href: "#",
    category: { name: "Article" },
    description:
      "Flexible decorating solutions including, Special wall coverings, General Wallpaper, Doors & Floor. ",
      imageUrl: require("@/assets/pap.jpg"),
  },
  {
    title: "Specialist Finishes",
    href: "#",
    category: { name: "Article" },
    description:
      "Our specialist finishes include hygienic and environmental coatings, fireproofing, internal and external protective coatings for varying materials and a full range of flooring solutions.",
    imageUrl:
      "https://images.unsplash.com/photo-1557847211-a0be5b0ed242?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=656&q=80",
  },
  {
    title: "Blowerproof",
    href: "#",
    category: { name: "Article" },
    description:
      "Blowerproof airtight liquid membrane is now BBA certified, used widely across Europe because of its ability to reach unrivalled levels of airtightness.",
    imageUrl: require("@/assets/bp.jpg"),
  },
];

export default {
  name: "ServicesBody",
  setup() {
    return {
      posts,
    };
  },
};
</script>

<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ]
  }
  ```
-->
<template>
  <div class="bg-white overflow-hidden">
    <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div
        class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"
      />
      <div
        class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none"
      >
        <div>
          <h2
            class="text-base text-secondary font-semibold tracking-wide uppercase"
          >
            est 1988
          </h2>
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
          >
            Kirby Brothers
          </h3>
        </div>
      </div>
      <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
        <div class="relative lg:row-start-1 lg:col-start-2">
          <svg
            class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
            />
          </svg>
          <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
            <figure>
              <div class="aspect-w-12 aspect-h-7">
                <img
                  class="rounded-lg shadow-lg object-cover object-center"
                  data-aos="slide-up"
                  src="https://images.unsplash.com/photo-1590846406792-0adc7f938f1d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=632&q=80"
                  alt="Whitney leaning against a railing on a downtown street"
                  width="1184"
                  height="1400"
                />
              </div>
            </figure>
          </div>
        </div>
        <div class="mt-8 lg:mt-0">
          <div class="text-base max-w-prose mx-auto lg:max-w-none">
            <p class="text-lg text-gray-500">

Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors.

            </p>
          </div>
          <div
            class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"
          >
            <p>
       We have worked in multiple industries such as Commercial, Pharmaceuticals/Healthcare, Residential, Hospitality & the public sector with companies such as CBRE, Becton Dickinson and Company (BD), Flynn, Pelco as well as organizations & government bodies such as The National Lottery, Dublin City Council & The Department of Foreign Affairs.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- BREAK BREAK -->
    <div class="relative py-16 bg-white overflow-hidden">
      <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          class="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            class="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            class="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div class="relative mx-auto lg:w-2/4 sm:w-full px-4 sm:px-6 lg:px-8">
        <div class="text-lg max-w-prose mx-auto">
          <h1>
            <span
              class="block text-base text-center text-secondary font-semibold tracking-wide uppercase"
              >Kirby Brothers</span
            >
            <span
              class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >Health & Safety</span
            >
          </h1>
        </div>
        <div id="hs" class="mt-6 mr-6 ml-6 mb-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Kirby Brothers Painters & Decorators Limited recognize health and
            safety as the most important part of our business & ensure
            compliance with the most up-to-date codes of practice, regulations
            and health and safety legislation.
          </p>

          <p>
            We have earned the reputation for delivering professional, safe,
            efficient and reliable painting & Decorating services
          </p>
          <p>
            <ul>
              <li class="mt-2">
             - We are independently audited and accredited by an External Health &
             Safety Officer
              </li>
              <li  class="mt-2">
             - We provide a safety briefing to our whole team prior
              to entering each site and carry out regular quality checks to ensure
              all aspects of the projects are being addressed properly
              </li>
              <li  class="mt-2">
              - All site
            personnel have Safe Pass & Manual Handling training.
              </li>
            </ul>
          </p>
          <figure>
            <img
              class="mx-auto rounded-lg mt-6"
                                  data-aos="fade-up"
              src="@/assets/bil.jpg"
              alt=""
              width="1310"
              height="873"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBody",
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-extrabold text-secondary">
        The world's best brands.
      </h2>
      <div class="flow-root mt-8 lg:mt-10">
        <div class="mt-4 -ml-8 flex flex-wrap mx-auto justify-between lg:-ml-4">
          <div
            class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4"
            v-for="(item, i) in items"
            :key="i"
          >
            <img class="h-14 bh" :src="item.src" alt="Tuple" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandClooud",
  data() {
    return {
      items: [
        {
          src: require("@/assets/paint/dulux.png"),
        },
        {
          src: require("@/assets/paint/farrow-ball-logo.png"),
        },
        {
          src: require("@/assets/paint/CrownPaints.png"),
        },
        {
          src: require("@/assets/paint/blowerproof.png"),
        },
        {
          src: require("@/assets/paint/mac.png"),
        },
        {
          src: require("@/assets/paint/Colourtrend.png"),
        },
        {
          src: require("@/assets/paint/fleetwood-logo.png"),
        },
      ],
    };
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <div class="relative">
      <div class="absolute inset-0">
        <img class="w-full h-full object-cover" src="@/assets/bes.jpg" alt="" />
        <div
          class="absolute inset-0 bg-gray-700"
          style="mix-blend-mode: multiply"
          aria-hidden="true"
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8"
      >
        <h1
          class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
          data-aos="flip-down"
          data-aos-easing="linear"
        >
          Services.
        </h1>
        <p
          class="mt-6 text-xl text-indigo-100 max-w-3xl"
          data-aos="fade-in"
          data-aos-easing="linear"
        >
          Painting, Decorating, Blowerproof.
        </p>
      </div>
    </div>
    <ServicesBody />
    <ContactBar />
  </div>
</template>

<script>
import ServicesBody from "../components/pagebody/ServicesBody.vue";
import ContactBar from "../components/other/ContactBar.vue";

export default {
  name: "About",
  components: {
    ContactBar,
    ServicesBody,
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-white foot" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <img class="h-10" src="@/assets/kbbt.png" alt="Company name" />
          <p class="text-gray-500 text-base">
            Kirby Brothers Painting & Decorating Limited.
          </p>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                GENERAL
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.gen" :key="item.name">
                  <router-link
                    :to="item.to"
                    class="bh text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                ABOUT
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.about" :key="item.name">
                  <router-link
                    :to="item.to"
                    class="bh text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                Services
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.services" :key="item.name">
                  <router-link
                    :to="item.to"
                    class="bh text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                Sectors
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.sectors" :key="item.name">
                  <router-link
                    :to="item.to"
                    class="bh text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-base text-gray-400 text-center xl:text-center">
          Designed & Created by Y-Squared &copy; 2021
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
const navigation = {
  gen: [
    { name: "Home", to: "/" },
    { name: "Portfolio", to: "/portfolio" },
    { name: "Contact Us", to: "/contact" },
  ],
  about: [
    { name: "Our Story", to: "/about" },
    { name: "Health & Safety", to: "/about#hs" },
  ],
  services: [
    { name: "Painting", to: "/services" },
    { name: "Specialist Finishes", to: "/services" },
    { name: "Decorating", to: "/services" },
    { name: "Blowerproof", to: "/services" },
  ],
  sectors: [
    { name: "Commercial", to: "/sectors" },
    { name: "Residential", to: "/sectors" },
    { name: "Hospitality", to: "/sectors" },
    { name: "Pharma & Healthcare", to: "/sectors" },
    { name: "Public", to: "/sectors" },
  ],
};

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>

<style>
.foot {
  box-shadow: 0 -20px 13px rgba(0, 0, 0, 0.03);
}
</style>

<template>
  <Nav class="sticky top-0 z-50 shadow-xl" />
  <router-view />
  <Footer />
</template>

<script>
import Nav from "./components/layout/Nav.vue";
import Footer from "./components/layout/Footer.vue";
import AOS from "aos";

AOS.init({ duration: 1000 });

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>

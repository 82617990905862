<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <main>
      <div>
        <!-- Hero card -->
        <div class="relative">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div class="w-full mx-auto">
            <div class="relative shadow-xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img
                  class="h-full w-full object-cover"
                  src="https://cdn.pixabay.com/photo/2019/02/25/22/52/building-4020801_960_720.jpg"
                  alt=""
                />
                <div
                  class="absolute inset-0 bg-gray-700"
                  style="mix-blend-mode: multiply"
                  aria-hidden="true"
                />
              </div>
              <div
                class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8"
              >
                <div
                  class="justify-center mx-auto lg:w-2/4 p-5 justify-items-center"
                >
                  <img data-aos="fade-in" src="@/assets/kbwt.png" alt="" />
                </div>

                <div
                  class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center"
                >
                  <div
                    class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5"
                  >
                    <router-link
                      data-aos="flip-up"
                      to="/services"
                      class="btw bh"
                    >
                      Services
                    </router-link>

                    <router-link
                      data-aos="flip-up"
                      to="/sectors"
                      class="btg bh"
                    >
                      Sectors
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- More main page content here... -->
      <LogoCloud />
      <HomeBody />
      <ContactBar />
    </main>
  </div>
</template>

<script>
import LogoCloud from "../components/base/LogoCloud.vue";
import HomeBody from "../components/pagebody/HomeBody.vue";
import ContactBar from "../components/other/ContactBar.vue";

export default {
  components: {
    ContactBar,
    HomeBody,
    LogoCloud,
  },
};
</script>

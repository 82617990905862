<template>
  <div>
    <!-- Testimonial/stats section -->
    <div class="relative mt-20 bg-gray-50">
      <div
        class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start"
      >
        <div class="relative sm:py-16 lg:py-0">
          <div
            aria-hidden="true"
            class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
          >
            <div
              class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"
            ></div>
            <svg
              class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width="404"
              height="392"
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="392"
                fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
              />
            </svg>
          </div>
          <div
            class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20"
          >
            <!-- Testimonial card-->
            <div
              class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden"
              data-aos="fade-right"
            >
              <img
                class="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1497366216548-37526070297c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0"
        >
          <!-- Content area -->
          <div class="pt-12 sm:pt-16 lg:pt-20">
            <h2
              class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
            >
              About Us
            </h2>
            <div class="mt-6 text-gray-500 space-y-6">
              <p class="text-lg">
                Kirby Brothers Limited is a Painting & Decorating company
                founded in 1988, Over the past 30 years we have provided the
                highest quality services to many of Ireland's leading companies
                & organisations across multiple sectors.
              </p>
              <router-link to="/about" class="btg bh"> Learn More </router-link>
              <div class="h-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SERVICE AND SECTORS -->
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative gra pt-16 pb-32 overflow-hidden">
      <div class="relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-white"
                >
                  <!-- Heroicon name: outline/inbox -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl"
                >
                  Services
                </h2>
                <p class="mt-4 text-lg text-white">
                  Over the last 30+ years Kirby Brothers has been working at the
                  highest level across many services, delivering high quality
                  consistent work to many different client across multiple
                  sectors.
                </p>
                <p class="mt-4 text-lg text-white">
                  <span class="font-bold">List of Services:</span>
                </p>
                <ul class="text-white">
                  <li>Painting Contracting</li>
                  <li>Special Finishes</li>
                  <li>Decorating</li>
                  <li>Fireproofing</li>
                  <li>Blowerproof</li>
                </ul>
                <div class="mt-6">
                  <router-link to="/services" class="btw bh">
                    View Services
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                data-aos="fade-left"
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="@/assets/dulux.jpg"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2"
          >
            <div>
              <div>
                <span
                  class="h-12 w-12 rounded-md flex items-center justify-center bg-white"
                >
                  <!-- Heroicon name: outline/sparkles -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                    />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2
                  class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl"
                >
                  Sectors
                </h2>
                <p class="mt-4 text-lg text-white">
                  Over the last 30+ years Kirby Brothers has been working at the
                  highest level in the construction industry, our knowledge and
                  expertise stretches across all sectors.
                </p>
                <p class="mt-4 text-lg text-white">
                  <span class="font-bold">List of Sectors:</span>
                </p>
                <ul class="text-white">
                  <li>Commercial</li>
                  <li>Residential</li>
                  <li>Hospitality</li>
                  <li>Pharma & Healthcare</li>
                  <li>Public</li>
                </ul>
                <div class="mt-6">
                  <router-link to="/sectors" class="btw bh">
                    View Sectors
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div
              class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                data-aos="fade-right"
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://cdn.pixabay.com/photo/2014/03/08/22/32/escalator-283448_960_720.jpg"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Brand Cloud -->
    <BrandCloud />
    <!-- PORFOLIO -->
    <div class="bg-gray-100">
      <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12">
          <div
            class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
          >
            <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Portfolio
            </h2>
            <p class="text-xl text-gray-700">
              Amongst our portfolio we have completed projects for companies
              such as BD(Becton, Dickinson and Company), CBRE, Flynn, Pelco, The
              National Lottery, The Department of Foreign Affairs & Dublin City
              Council to name a Few.
            </p>
          </div>
          <ul
            class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            <li v-for="person in people" :key="person.name" data-aos="fade-up">
              <div class="space-y-4">
                <div class="aspect-w-3 aspect-h-2">
                  <img
                    class="object-cover shadow-lg rounded-lg bh"
                    :src="person.imageUrl"
                    alt=""
                  />
                </div>
              </div>
            </li>
          </ul>
          <div class="max-w-md mx-auto">
            <router-link to="/portfolio" class="btg bh">
              View Portfolio
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandCloud from "../base/BrandCloud.vue";

const people = [
  {
    imageUrl:
      "https://images.pexels.com/photos/1457844/pexels-photo-1457844.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    imageUrl:
      "https://cdn.pixabay.com/photo/2015/11/15/20/49/modern-office-1044807_960_720.jpg",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
  },
  // More people...
];

export default {
  name: "HomeBody",
  components: {
    BrandCloud,
  },
  setup() {
    return {
      people,
    };
  },
};
</script>

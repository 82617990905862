<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-extrabold text-secondary">
        Trusted by Irelands leading companies.
      </h2>
      <div class="flow-root mt-8 lg:mt-10">
        <div class="mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
          <div
            class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4"
            v-for="(item, i) in items"
            :key="i"
          >
            <img class="h-12 bh" :src="item.src" alt="Tuple" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoClooud",
  data() {
    return {
      items: [
        {
          src: require("@/assets/logos/cbre.jpg"),
        },
        {
          src: require("@/assets/logos/bd.png"),
        },
        {
          src: require("@/assets/logos/megamenu-logo.png"),
        },
        {
          src: require("@/assets/logos/flynn.png"),
        },
        {
          src: require("@/assets/logos/dcc.png"),
        },
        {
          src: require("@/assets/logos/pelco.png"),
        },
        {
          src: require("@/assets/logos/dfa.png"),
        },
        {
          src: require("@/assets/paint/blowerproof.png"),
        },
      ],
    };
  },
};
</script>

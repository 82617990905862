import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Services from "../views/Services.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Kirby Brothers | Painters & Decorators",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
        {
          property: "og:description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
      ],
    },
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About Us | Kirby Brothers",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. We have worked in multiple industries such as Commercial, Pharmaceuticals/Healthcare, Residential, Hospitality & the public sector with companies such as CBRE, Becton Dickinson and Company (BD), Flynn, Pelco as well as organizations & government bodies such as The National Lottery, Dublin City Council & The Department of Foreign Affairs.",
        },
        {
          property: "og:description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. We have worked in multiple industries such as Commercial, Pharmaceuticals/Healthcare, Residential, Hospitality & the public sector with companies such as CBRE, Becton Dickinson and Company (BD), Flynn, Pelco as well as organizations & government bodies such as The National Lottery, Dublin City Council & The Department of Foreign Affairs.",
        },
      ],
    },
    component: About,
  },
  {
    path: "/services",
    name: "Services",
    meta: {
      title: "Services | Kirby Brothers",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Over the last 30+ years Kirby Brothers has been working at the highest level across many services, delivering high quality consistent work to many different client across multiple sectors. We Specialise in Painting & Decorating contracting but also cater to other specific services like special finishes & Blowerproof Solutions.",
        },
        {
          property: "og:description",
          content:
            "Over the last 30+ years Kirby Brothers has been working at the highest level across many services, delivering high quality consistent work to many different client across multiple sectors. We Specialise in Painting & Decorating contracting but also cater to other specific services like special finishes & Blowerproof Solutions.",
        },
      ],
    },
    component: Services,
  },
  {
    path: "/sectors",
    name: "Sectors",
    meta: {
      title: "Sectors | Kirby Brothers",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Over the last 30+ years Kirby Brothers has been working at the highest level in the construction industry, our knowledge and expertise stretches across all sectors. Our team is specifically trained to cater to the variety of needs & environments on every project we take on. Our experienced team gives us the capability to deliver large projects, at the highest standard of finish on time, every time, ensuring customer satisfaction across all sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
        {
          property: "og:description",
          content:
            "Over the last 30+ years Kirby Brothers has been working at the highest level in the construction industry, our knowledge and expertise stretches across all sectors. Our team is specifically trained to cater to the variety of needs & environments on every project we take on. Our experienced team gives us the capability to deliver large projects, at the highest standard of finish on time, every time, ensuring customer satisfaction across all sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
      ],
    },
    component: () => import("../views/Sectors.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Portfolio | Kirby Brothers",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Amongst our portfolio we have completed projects for pharmaceutical giant BD(Becton, Dickinson and Company) , The world's largest real estate company CBRE, as well as top tier irish companies such as Flynn & Pelco and Organizations/Government Bodies such as The National Lottery, The Department of Foreign Affairs & Dublin City Council to name a few.",
        },
        {
          property: "og:description",
          content:
            "Amongst our portfolio we have completed projects for pharmaceutical giant BD(Becton, Dickinson and Company) , The world's largest real estate company CBRE, as well as top tier irish companies such as Flynn & Pelco and Organizations/Government Bodies such as The National Lottery, The Department of Foreign Affairs & Dublin City Council to name a few.",
        },
      ],
    },
    component: () => import("../views/Portfolio.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact | Kirby Brothers",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Feel free to contact us regards any inquiries you may have regards of the services we offer! Contact information, Phone number: +353 86 2590582 Email: larrykirbybros@gmail.com",
        },
        {
          property: "og:description",
          content:
            "Feel free to contact us regards any inquiries you may have regards of the services we offer!",
        },
      ],
    },
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",
    meta: {
      title: "Page Not Found | Kirby Brothers",
      dynamicParam: "-",
      name: "Portfolio",
      metaTags: [
        {
          name: "description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
        {
          property: "og:description",
          content:
            "Kirby Brothers Limited is a Painting & Decorating company founded in 1988, Over the past 30 years we have provided the highest quality services to many of Ireland's leading companies & organisations across multiple sectors. List of Services: Painting Contracting, Special Finishes, Decorating, Fireproofing, Blowerproof List of Sectors: Commercial, Residential, Hospitality, Pharma & Healthcare, Public",
        },
      ],
    },
    component: () => import("../views/NotFound.vue"),
  },
  // {
  //   path: "*",
  //   name: "NotFound",
  //   component: () => import("../views/NotFound.vue"),
  // },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    setTimeout(() => {
      const element = document.getElementById(to.hash.replace(/#/, ""));
      if (element && element.scrollIntoView) {
        element.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }, 500);
    return {
      el: to.hash,
    };
  } else if (savedPosition) {
    return savedPosition;
  }
  return {
    top: 0,
  };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // window.console.log(to.path)
  // if (localStorage.getItem('roleID') === null && to.path != '/login') {
  //   window.location.href = '/login'
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title && r.meta.dynamicParam);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (
      nearestWithTitle.meta.dynamicParam == undefined ||
      nearestWithTitle.meta.dynamicParam == "-"
    ) {
      document.title = nearestWithTitle.meta.title;
    } else {
      const pageTitle = nearestWithTitle.meta.title.replace(
        ":" + nearestWithTitle.meta.dynamicParam,
        to.params[nearestWithTitle.meta.dynamicParam]
          .replace(/[^a-zA-Z0-9]/g, " ")
          .replace(/\b[a-z]/g, (x) => x.toUpperCase())
      );
      document.title = pageTitle;
    }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
